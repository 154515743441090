<section class="py-3 py-md-5 py-xl-8">
  <div class="section-title">
    <h2>{{"partners_headline" | translate}}</h2>
    <!-- <p>
      L’équipe est structurée en 3 pôles d’expertise : conseil en Data
      Science,Machine Learning et Business Intelligence.
    </p> -->
  </div>

  <div class="container py-5">
    <div class="row g-4 align-items-center justify-content-center">
      <div class="col-sm-3">
        <div class="p-4 mb-4 wow fadeIn" data-wow-delay="0.3s">
          <img
            src="https://mp.s81c.com/pwb-production/5aa5b2b85de69c4d806209283d9a4e0b/companyLogoImg_7105e8ac-5cb6-4897-a472-851d9001317a_8a86dd97-bc5d-48a7-8cb2-799707eb8a61.png"
            alt="Sysdig"
          />
        </div>
      </div>
      <div class="col-sm-3">
        <div class="p-4 mb-4 wow fadeIn" data-wow-delay="0.3s">
          <img
            src="https://www.jig-saw.com/wp-content/uploads/2020/12/Google_Cloud_Partner-2.png"
            alt="Google Cloud Partner"
          />
        </div>
      </div>
      <div class="col-sm-3">
        <div class="p-4 mb-4 wow fadeIn" data-wow-delay="0.3s">
          <img
            src="https://assets.bizclikmedia.net/1800/2425f7831be8209d6623d98ddebd2176:c99be5626184d640e74df31a50fe140d/rapid7.png"
            alt="rapid 1"
          />
        </div>
      </div>
      <div class="col-sm-3">
        <div class="p-4 mb-4 wow fadeIn" data-wow-delay="0.3s">
          <img
            src="https://www.cloudops.com/cloudops-press/cloudops-is-now-a-kubernetes-training-partner/KTP-copy.png"
            alt="kubernetes training partner"
          />
        </div>
      </div>
      <div class="col-sm-3">
        <div class="p-4 mb-4 wow fadeIn" data-wow-delay="0.3s">
          <img src="https://cdn.worldvectorlogo.com/logos/saint-gobain-logo.svg" alt="Sysdig" />
        </div>
      </div>
      <div class="col-sm-3">
        <div class="p-4 mb-4 wow fadeIn" data-wow-delay="0.3s">
          <img src="https://cdn.worldvectorlogo.com/logos/logo-celio-2016.svg" alt="Sysdig" />
        </div>
      </div>
      <div class="col-sm-3">
        <div class="p-4 mb-4 wow fadeIn" data-wow-delay="0.3s">
          <img src="https://cdn.worldvectorlogo.com/logos/veolia.svg" alt="" />
        </div>
      </div>
      <div class="col-sm-3">
        <div class="p-4 mb-4 wow fadeIn" data-wow-delay="0.3s">
          <img src="https://cdn.worldvectorlogo.com/logos/totalenergies-1.svg" alt="Sysdig" />
        </div>
      </div>
      <div class="col-sm-3">
        <div class="p-4 mb-4 wow fadeIn" data-wow-delay="0.3s">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Logo_actuel_de_Norauto.png/1200px-Logo_actuel_de_Norauto.png"
            alt="Sysdig" />
        </div>
      </div>
      <div class="col-sm-3">
        <div class="p-4 mb-4 wow fadeIn" data-wow-delay="0.3s">
          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Thales_Logo.svg/484px-Thales_Logo.svg.png"
            alt="Sysdig" />
        </div>
      </div>
      <div class="col-sm-3">
        <div class="p-4 mb-4 wow fadeIn" data-wow-delay="0.3s">
          <img src="https://findlogovector.com/wp-content/uploads/2023/05/trace-one-logo-vector-2023.png"
            alt="Google Cloud Partner" />
        </div>
      </div>
      <div class="col-sm-3">
        <div class="p-4 mb-4 wow fadeIn" data-wow-delay="0.3s">
          <img src="https://gtt.fr/sites/default/files/GTT-LOGO-BASELINE-RVB_1.png" alt="rapid 1" />
        </div>
      </div>
      <div class="col-sm-3">
        <div class="p-4 mb-4 wow fadeIn" data-wow-delay="0.3s">
          <img src="https://cdn.worldvectorlogo.com/logos/leroy-merlin-2.svg" alt="kubernetes training partner" />
        </div>
      </div>
    </div>
  </div>

</section>