import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
})
export class AboutComponent implements OnInit, AfterViewInit {
  @ViewChild('KeyNumbers') keyNumbersSection!: ElementRef;

  ngOnInit(): void {
    // No need to throw an error; this can be removed.
  }

  ngAfterViewInit(): void {
    this.initIntersectionObserver();
  }

  private initIntersectionObserver() {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          this.initCounters();
          observer.unobserve(this.keyNumbersSection.nativeElement);
        }
      },
      {
        threshold: 0.1,
      }
    );

    observer.observe(this.keyNumbersSection.nativeElement);
  }

  private initCounters() {
    this.animateCounter('Employes', 30, false); // Pass false to avoid $ sign
    this.animateCounter('Turnover', 1500000, true); // Pass true to add $ sign
    this.animateCounter('Experts', 3, false); // Pass false to avoid $ sign
    this.animateCounter('Partners', 10, false); // Additional example for Partners
  }

private animateCounter(id: string, target: number, withDollar: boolean) {
  const element = document.getElementById(id);
  if (element) {
    let count = 0;
    const interval = setInterval(() => {
      count += Math.ceil(target / 100);
      if (count >= target) {
        count = target;
        clearInterval(interval);
        element.textContent = this.formatNumber(count, withDollar, true); // Final display with "M$"
      } else {
        element.textContent = this.formatNumber(count, withDollar, false); // Display without "M$"
      }
    }, 60);
  }
}
private formatNumber(value: number, withDollar: boolean, isFinal: boolean): string {
  let formattedValue: string;
  if (value >= 1000000) {
    formattedValue = (value / 1000000).toFixed(1) + (isFinal && withDollar ? 'M€' : 'M');
  } else if (value >= 1000) {
    formattedValue = (value / 1000).toFixed(1) + 'K';
  } else {
    formattedValue = value.toString();
  }
  return formattedValue;
}
}
