<!-- Start Page Title Area -->
<div class="page-title-area item-bg5">
<!--   <div class="container">
    <div class="page-title-content">
      <h2>{{"contact_page" | translate}}</h2>
    </div>
  </div> -->

  <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="contact-area ptb-110">
  <div class="container">
    <div class="section-title">
      <span>{{"contactAreaTitle" |  translate}}</span>
    </div>

    <div class="contact-form">
      <div class="row align-items-center">
        <div class="col-lg-5 col-md-12">
          <div class="contact-image">
            <img src="assets/images/contact.jpg" alt="image" />
          </div>
        </div>

        <!-- 
                <div class="col-lg-7 col-md-12">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" required data-error="Please enter your name" placeholder="Name">
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control" required data-error="Please enter your email" placeholder="Email">
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" name="phone_number" id="phone_number" required data-error="Please enter your number" class="form-control" placeholder="Phone">
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" required data-error="Please enter your subject" placeholder="Subject">
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="5" required data-error="Write your message" placeholder="Your Message"></textarea>
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary">Send Message</button>
                                <div id="msgSubmit" class="h3 text-center hidden"></div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </form>
                </div>
                -->

        <div class="contact-info col-lg-7 col-md-12">
          <div class="contact-info-content">
            <!--
                        <h3>CALL US</h3>
                        <h2>
                            <a href="tel:+0881306298615">+088 130 629 8615</a>
                            <span>OR </span>
                        </h2> 
                        <br>
                        -->
            <h3>{{"contactUs" |  translate}}</h3>
            <h2>
              <a href="mailto:rh@cloudiasys.com">rh@cloudiasys.com</a>
            </h2>

            <ul class="social">
              <li>
                <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"
                  ><i class="fab fa-facebook-f"></i
                ></a>
              </li>
              <li>
                <a href="#" target="_blank"
                  ><i class="fab fa-linkedin-in"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Contact Area -->
<div
  class="container-fluid bg-footer footer-area mt-5 py-5 wow fadeIn"
  data-wow-delay="0.1s"
>
  <div class="container py-5">
    <div class="row g-5">
      <div class="col-lg-3 col-md-6">
        <h3 class="text-white mb-4">{{"agencyParis.title" |  translate}}</h3>
        <p class="mb-2 text-white">
          <i class="fa fa-map-marker-alt me-3"></i>{{"agencyParis.address" |  translate}}
        </p>
        <p class="mb-2 text-white">
          <i class="fa fa-phone me-3"></i>+01 48 61 07 44
        </p>
      </div>
      <div class="col-lg-3 col-md-6">
        <h3 class="text-white mb-4">{{"agencySaintQuentin.title" |  translate}}</h3>
        <p class="mb-2 text-white">
          <i class="fa fa-map-marker-alt me-3"></i>{{"agencySaintQuentin.address" |  translate}}
        </p>
        <p class="mb-2 text-white">
          <i class="fa fa-phone me-3"></i>+03 23 06 03 33
        </p>
      </div>
      <div class="col-lg-3 col-md-6">
        <h3 class="text-white mb-4">{{"agencyTunis.title" |  translate}}</h3>
        <p class="mb-2 text-white">
          <i class="fa fa-map-marker-alt me-3"></i>{{"agencyTunis.address" |  translate}}
        </p>
        <p class="mb-2 text-white">
          <i class="fa fa-phone me-3"></i>+216 29 35 43 40
        </p>
      </div>
      <div class="col-lg-3 col-md-6">
        <h3 class="text-white mb-4">Contacts</h3>
        <p class="mb-2 text-white">
          <i class="fa fa-envelope me-3"></i>CONTACT@CLOUDIASYS.COM
        </p>
        <p class="mb-2 text-white">
          <i class="fa fa-globe me-3"></i>WWW.CLOUDIASYS.COM
        </p>
      </div>
    </div>
  </div>
</div>
