<section class="do_section layout_padding pb-80">
    <div class="container">
      <!-- <div class="section-title">
        <h2>Notre Mession</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna
        </p>
      </div> -->
      <div class="do_container">
        <div class="box arrow_bg" (click)="scrollToDataPole()">
          <div class="img-box bg-warning">
            <i class="fa fa-3x fa-database text-white"></i>
          </div>
          <div class="detail-box">
            <h6>{{ 'messions.DATA' | translate }}</h6>
          </div>
        </div>
        <div class="box arrow_bg" (click)="scrollToDevOpsPole()">
          <div class="img-box bg-primary">
            <i class="fa fa-3x fa-gears text-white"></i>
          </div>
          <div class="detail-box">
            <h6>{{ 'messions.DEVOPS' | translate }}</h6>
          </div>
        </div>
        <div class="box arrow_bg" (click)="scrollToCyberPole()">
          <div class="img-box text-dark">
            <i class="fa fa-3x fa-lock text-white"></i>
          </div>
          <div class="detail-box">
            <h6>{{ 'messions.SECURITY' | translate }}</h6>
          </div>
        </div>
        <div class="box arrow_bg" (click)="scrollToFormationPole()">
          <div class="img-box bg-success">
            <i class="fa fa-3x fa-graduation-cap text-white"></i>
          </div>
          <div class="detail-box">
            <h6>{{ 'messions.TRAINING' | translate }}</h6>
          </div>
        </div>
        <div class="box arrow_bg" (click)="scrollToSupportPole()">
          <div class="img-box bg-info">
            <i class="fa fa-3x fa-headphones text-white"></i>
          </div>
          <div class="detail-box">
            <h6>{{ 'messions.SUPPORT' | translate }}</h6>
          </div>
        </div>
      </div>
    </div>
  </section>