<!-- Start Page Title Area -->
<div class="page-title-area item-bg6">
  <div class="container">
    <div class="training-card">
      <h4 class="text-white mt-3">{{ "training1Area.editor" | translate }}</h4>
      <p class="text-white">{{ "training1Area.microsoft" | translate }}</p>
      <h4 class="text-white">
        {{ "training1Area.certification" | translate }}
      </h4>
      <p class="text-white">Certification DP-200</p>
      <h4 class="text-white">{{ "training1Area.audience" | translate }}</h4>
      <p class="text-white">
        {{ "training1Area.audienceType" | translate }}
      </p>
    </div>
    <div class="page-title-content">
      <h2 [innerHTML]="'training1Area.pageTitle' | translate"></h2>
      <p
        class="text-white font-weight-bold"
        [innerHTML]="'training1Area.description' | translate"
      ></p>
    </div>
  </div>
  <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<section class="blog-details-area ptb-110">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-12">
        <h1>{{ "blogDetailsArea.presentation" | translate }}</h1>
        <h2>{{ "blogDetailsArea.objectives" | translate }}</h2>
        <p class="text-left">
          {{ "blogDetailsArea.objectivesText" | translate }}
        </p>

        <h1>{{ "blogDetailsArea.program" | translate }}</h1>
        <p [innerHTML]="'blogDetailsArea.programText' | translate">
        
        </p>

        <h2>{{ "blogDetailsArea.prerequisites" | translate }}</h2>
        <p class="text-left">
          {{ "blogDetailsArea.prerequisitesText" | translate }}
        </p>

        <h2>{{ "blogDetailsArea.targetAudience" | translate }}</h2>
        <p>
          {{ "blogDetailsArea.targetAudienceText" | translate }}
        </p>
      </div>
    </div>
  </div>
</section>
<!-- End Blog Details Area -->
