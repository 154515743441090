<!-- Start Page Title Area -->
<div class="page-title-area item-bg6">
  <div class="container">
    <div class="page-title-content">
      <h2>{{ "pageTitleArea.heading" | translate }}</h2>
      <p class="text-white">
        {{ "pageTitleArea.description" | translate }}
      </p>
      <a href="training#nosFormations" class="btn btn-light rounded">{{
        "pageTitleArea.button" | translate
      }}</a>
    </div>
  </div>
  <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area ptb-110" id="nosFormations">
  <div class="container">
    <div class="services-details-overview">
      <div class="services-details-desc">
        <a href="training1">
          <h3>{{ "servicesDetailsArea.azure.title" | translate }}</h3>
        </a>
        <p>
          {{ "servicesDetailsArea.azure.description" | translate }}
        </p>
      </div>
      <div class="services-details-image wow fadeInUp">
        <a href="training1"
          ><img src="assets/img/services-details/azure.jpg" alt="image"
        /></a>
      </div>
    </div>

    <div class="services-details-overview">
      <div class="services-details-image wow fadeInUp">
        <a href="training2"
          ><img src="assets/img/services-details/powerbi1.jpg" alt="image"
        /></a>
      </div>
      <div class="services-details-desc">
        <a href="training2">
          <h3>DevOps</h3>
        </a>
        <p
          [innerHTML]="'servicesDetailsArea.devops.description' | translate"
        ></p>
        <p
          [innerHTML]="'servicesDetailsArea.devops.subDescription' | translate"
        ></p>
        <div class="services-details-accordion">
          <ul class="accordion">
            <li class="accordion-item">
              <a class="accordion-title active">
                <i class="fas fa-plus"></i
                >{{ "servicesDetailsArea.devops.objectives.title" | translate }}
              </a>
              <p
                class="accordion-content show"
                [innerHTML]="
                  'servicesDetailsArea.devops.objectives.points' | translate
                "
              ></p>
            </li>
            <li class="accordion-item">
              <a class="accordion-title">
                <i class="fas fa-plus"></i>
                {{ "servicesDetailsArea.devops.audience.title" | translate }}
              </a>
              <p
                class="accordion-content"
                [innerHTML]="
                  'servicesDetailsArea.devops.audience.points' | translate
                "
              ></p>
            </li>
            <li class="accordion-item">
              <a class="accordion-title">
                <i class="fas fa-plus"></i> {{ "servicesDetailsArea.devops.prerequisites.title" | translate }}
              </a>
              <p class="accordion-content"  [innerHTML]="
              'servicesDetailsArea.devops.prerequisites.points' | translate
            ">
               
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Services Details Area -->
