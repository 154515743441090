<div class="page-title-area item-bg2">
  <div class="container">
    <div class="page-title-content">
      <h2>{{"page-title.services" | translate}}</h2>
    </div>
  </div>
  <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
</div>
<!-- End Page Title Area -->
<!-- Start About Area -->
<section class="about-area ptb-110">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="about-content">
          <h2>{{"about_services.title" | translate}}</h2>
          <p>
            {{"about_services.paragraph1" | translate}}
          </p>
          <p>
            {{"about_services.paragraph2" | translate}}
          </p>
          <a routerLink="/training" class="btn btn-primary rounded"
            >{{"about_services.button" | translate}}</a
          >
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="about-image">
          <img
            src="assets/img/about/1.jpg"
            alt="image"
            width="550px"
            height="360px"
            style="box-shadow: 0 0 2em grey; border-radius: 4px"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="shape-img1"><img src="assets/img/shape/1.png" alt="image" /></div>
  <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image" /></div>
  <div class="shape-img3"><img src="assets/img/shape/3.png" alt="image" /></div>
  <div class="shape-img4"><img src="assets/img/shape/4.svg" alt="image" /></div>
  <div class="shape-img5"><img src="assets/img/shape/5.svg" alt="image" /></div>
  <div class="shape-img6"><img src="assets/img/shape/6.png" alt="image" /></div>
  <div class="dot-shape1">
    <img src="assets/img/shape/dot1.png" alt="image" />
  </div>
  <div class="dot-shape2">
    <img src="assets/img/shape/dot3.png" alt="image" />
  </div>
</section>
<!-- End About Area -->
<!-- Start Services Area -->
<section class="services-area ptb-110" id="servicesArea">
  <div class="container">
    <div class="section-title">
      <h2>{{"services_page.title" | translate}}</h2>
      <p>
        {{"services_page.description" | translate}}
      </p>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <a href="#">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-artificial-intelligence"></i>
            </div>
            <h3>{{"services_page.data_science.title" | translate}}</h3>
            <p>
              {{"services_page.data_science.description" | translate}}
            </p>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <a href="#">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-machine-learning"></i>
            </div>
            <h3>{{"services_page.machine_learning.title" | translate}}</h3>
            <p>
              {{"services_page.machine_learning.description" | translate}}
            </p>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <a href="#">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-robot"></i>
            </div>
            <h3>{{"services_page.business_intelligence.title" | translate}}</h3>
            <p>
              {{"services_page.business_intelligence.description" | translate}}
            </p>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <a href="#">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-automatic"></i>
            </div>
            <h3>{{"services_page.data_analytics.title" | translate}}</h3>
            <p>
              {{"services_page.data_analytics.description" | translate}}
            </p>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <a href="#">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-income"></i>
            </div>
            <h3>{{"services_page.data_visualisation.title" | translate}}</h3>
            <p>
              {{"services_page.data_visualisation.description" | translate}}
            </p>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <a href="#">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-tick"></i>
            </div>
            <h3>{{"services_page.data_architecture.title" | translate}}</h3>
            <p>
              {{"services_page.data_architecture.description" | translate}}
            </p>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <a href="#">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-gear"></i>
            </div>
            <h3>{{"services_page.devOps.title" | translate}}</h3>
            <p>
              {{"services_page.devOps.description" | translate}}
            </p>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <a href="#">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-locked"></i>
            </div>
            <h3>{{"services_page.securite.title" | translate}}</h3>
            <p>
              {{"services_page.securite.description" | translate}}
            </p>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <a href="#">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-molecular"></i>
            </div>
            <h3>{{"services_page.formation.title" | translate}}</h3>
            <p>
              {{"services_page.formation.description" | translate}}
            </p>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <a href="#">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-ceo"></i>
            </div>
            <h3>{{"services_page.support.title" | translate}}</h3>
            <p>
              {{"services_page.support.description" | translate}}
            </p>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image" /></div>
  <div class="shape-img3"><img src="assets/img/shape/3.png" alt="image" /></div>
  <div class="shape-img4"><img src="assets/img/shape/4.svg" alt="image" /></div>
  <div class="shape-img5"><img src="assets/img/shape/5.svg" alt="image" /></div>
  <div class="shape-img7"><img src="assets/img/shape/3.png" alt="image" /></div>
  <div class="dot-shape1">
    <img src="assets/img/shape/dot1.png" alt="image" />
  </div>
  <div class="dot-shape2">
    <img src="assets/img/shape/dot3.png" alt="image" />
  </div>
  <div class="dot-shape4">
    <img src="assets/img/shape/dot4.png" alt="image" />
  </div>
  <div class="dot-shape5">
    <img src="assets/img/shape/dot5.png" alt="image" />
  </div>
  <div class="dot-shape6">
    <img src="assets/img/shape/dot6.png" alt="image" />
  </div>
</section>
<!-- End Services Area -->

<!-- Start Request Form -->
<!-- <section class="faq-area">
  <div class="container">
    <div class="faq-contact">
      <div class="section-title">
        <h2>Nous sommes à votre écoute</h2>
        <p>Vous avez une question, un besoin?</p>
      </div>
      <div class="faq-contact-form">
        <div class="row align-items-center">
          <div class="col-lg-10 col-md-12">
            <form id="contactForm">
              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      class="form-control"
                      required
                      data-error="Please enter your name"
                      placeholder="Name"
                    />
                    <div class="help-block with-errors"></div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      class="form-control"
                      required
                      data-error="Please enter your email"
                      placeholder="Email"
                    />
                    <div class="help-block with-errors"></div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      name="phone_number"
                      id="phone_number"
                      required
                      data-error="Please enter your number"
                      class="form-control"
                      placeholder="Phone"
                    />
                    <div class="help-block with-errors"></div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      name="msg_subject"
                      id="msg_subject"
                      class="form-control"
                      required
                      data-error="Please enter your subject"
                      placeholder="Subject"
                    />
                    <div class="help-block with-errors"></div>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <textarea
                      name="message"
                      class="form-control"
                      id="message"
                      cols="30"
                      rows="5"
                      required
                      data-error="Write your message"
                      placeholder="Your Message"
                    ></textarea>
                    <div class="help-block with-errors"></div>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12">
                  <button type="submit" class="btn btn-primary">
                    Send Message
                  </button>
                  <div id="msgSubmit" class="h3 text-center hidden"></div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
<!-- End Request Form -->
