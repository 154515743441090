import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.css']
})
export class TrainingComponent implements OnInit {

  constructor(private translate: TranslateService) {}

  ngOnInit() {
// Set the default language or use the stored language
const lang = localStorage.getItem('lang') || 'FR';
this.translate.use(lang);  }

}
