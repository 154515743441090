import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-reference',
    templateUrl: 'reference.component.html',
    styleUrls: ['reference.component.css']
})

export class ReferncesComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}