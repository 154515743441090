import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { RegisterComponent } from './register';
import { ProfileComponent } from './profile';
import { AboutComponent } from './about/about.component';
import { AuthGuard } from './guards';
import { RegisterEntrepriseComponent } from './register-entreprise/register-entreprise.component';
import { ServicesfrComponent } from './servicesfr/servicesfr.component';
import { HomefrComponent } from './homefr/homefr.component';
import { ContactComponent } from './contact/contact.component';
import { JoinusComponent } from './joinus/joinus.component';
import { TrainingComponent } from './training/training.component';
import { Training1Component } from './training1/training1.component';
import { Training2Component } from './training2/training2.component';
import { ReferncesComponent} from './reference/reference.component'
import { NgModule } from '@angular/core';

const appRoutes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'homefr' },
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'home', component: HomeComponent },
  { path: 'homefr', component: HomefrComponent },
  { path: 'about', component: AboutComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'services', component: ServicesfrComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'joinus', component: JoinusComponent },
  { path: 'registerentreprise', component: RegisterEntrepriseComponent },
  { path: 'training', component: TrainingComponent },
  { path: 'training1', component: Training1Component },
  { path: 'training2', component: Training2Component },
  { path :'reference', component: ReferncesComponent},
  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

//export const routing = RouterModule.forRoot(appRoutes);
@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
