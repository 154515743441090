<!-- Start Main Banner Area -->
<div class="main-banner">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container-fluid">
        <div class="main-banner-content">
          <h1>Making data working your way</h1>
          <p>
            CloudiaSys today is able to supply the necessary help, information,
            and positive experience of maintaining intimacy with customers.
          </p>
          <div class="btn-box">
            <a href="home#srvArea" class="btn btn-secondairy">Learn more</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Main Banner Area -->

<!-- Start Featured Services Area -->
<div class="featured-services-area">
  <div class="container">
    <div class="featured-services-inner">
      <div class="row m-0">
        <div class="col-lg-4 col-md-6 col-sm-6 p-0">
          <div class="single-featured-services-box">
            <div class="icon">
              <i class="flaticon-robot"></i>
            </div>
            <h3><a href="#">Data Analytics & Visualisation</a></h3>
            <p>
              Empower your business leaders with intuitive analytics, so they
              can uncover valuable insights for themselves.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 p-0">
          <div class="single-featured-services-box">
            <div class="icon">
              <i class="flaticon-artificial-intelligence"></i>
            </div>
            <h3><a href="#">Data Strategy</a></h3>
            <p>
              Reach your data goals – from tightening governance and improving
              decision-making, to driving innovation, transformation, and
              growth.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 p-0">
          <div class="single-featured-services-box">
            <div class="icon">
              <i class="flaticon-machine-learning"></i>
            </div>
            <h3><a href="#">Data Science</a></h3>
            <p>
              Let our experts transform your development practices, build and
              manage your infrastructure, and even do the science for you.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Featured Services Area -->

<!-- Start About Area -->
<section class="about-area ptb-110">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="about-content">
          <h2>Our Recent Story</h2>
          <p>
            CloudiaSys is a premier IT consultancy composed of expert database,
            cloud, virtualization, and infrastructure specialists. We are
            committed to doing what’s in our client’s best interests, and as a
            result, tailor our services to your specific needs.
          </p>
          <a routerLink="/about" class="btn btn-primary">Learn More</a>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="about-image">
          <img
            src="assets/img/about/1.jpg"
            alt="image"
            width="550"
            height="360"
            style="box-shadow: 0 0 2em grey; border-radius: 4px"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="shape-img1"><img src="assets/img/shape/1.png" alt="image" /></div>
  <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image" /></div>
  <div class="shape-img3"><img src="assets/img/shape/3.png" alt="image" /></div>
  <div class="shape-img4"><img src="assets/img/shape/4.svg" alt="image" /></div>
  <div class="shape-img5"><img src="assets/img/shape/5.svg" alt="image" /></div>
  <div class="shape-img6"><img src="assets/img/shape/6.png" alt="image" /></div>
  <div class="dot-shape1">
    <img src="assets/img/shape/dot1.png" alt="image" />
  </div>
  <div class="dot-shape2">
    <img src="assets/img/shape/dot3.png" alt="image" />
  </div>
</section>
<!-- End About Area -->

<!-- Start Services Area -->
<div class="services-area ptb-110" id="srvArea">
  <div class="container">
    <div class="section-title">
      <h2>We Offer Professional Solutions</h2>
      <p>
        We have the proven track record to provide expert skill, evidence-based
        analysis, leadership, and industry experience through our services.
      </p>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <a href="#">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-automatic"></i>
            </div>
            <h3>Data Analytics</h3>
            <p>
              We help you gain insight from any form of data. System logs,
              security logs, structured or scattered data. Our experts work on
              complex datasets to map them in a logical format to understand the
              data.
            </p>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <a href="#">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-locked"></i>
            </div>
            <h3>Data Science</h3>
            <p>
              We deliver unique and customized data science solutions by using
              various Data Science tools and technologies. Our certified Data
              Science experts have hands-on experience in developing solutions
              that match your business needs.
            </p>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <a href="#">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-molecular"></i>
            </div>
            <h3>AI & ML Development</h3>
            <p>
              We help enterprises to develop smart and intelligent products or
              applications. It has deep learning and Machine Learning
              capabilities. We have expertise in working with AI and ML
              toolkits.
            </p>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <a href="#">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-income"></i>
            </div>
            <h3>Data Visualisation</h3>
            <p>
              Let your infrastructure help your IT department move at the speed
              of your business and improve your decision-making with advanced
              analytics and visualizations.
            </p>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <a href="#">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-ceo"></i>
            </div>
            <h3>Data Migration</h3>
            <p>
              Transform your traditional data collection source to a modernized
              system to get the best out of it. We work closely with business
              enterprises to understand their future needs and current
              traditions, providing unique enterprise solutions.
            </p>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <a href="#">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-gear"></i>
            </div>
            <h3>Data Warehousing</h3>
            <p>
              CloudiaSys consults enterprises on extracting, transforming, and
              loading data in a structured format. We provide a robust and
              fault-tolerant architecture for the entire ETL process.
            </p>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image" /></div>
  <div class="shape-img3"><img src="assets/img/shape/3.png" alt="image" /></div>
  <div class="shape-img4"><img src="assets/img/shape/4.svg" alt="image" /></div>
  <div class="shape-img5"><img src="assets/img/shape/5.svg" alt="image" /></div>
  <div class="shape-img7"><img src="assets/img/shape/3.png" alt="image" /></div>
  <div class="dot-shape1">
    <img src="assets/img/shape/dot1.png" alt="image" />
  </div>
  <div class="dot-shape2">
    <img src="assets/img/shape/dot3.png" alt="image" />
  </div>
  <div class="dot-shape4">
    <img src="assets/img/shape/dot4.png" alt="image" />
  </div>
</div>
<!-- End Services Area -->

<!-- Start CTA Area -->
<div class="cta-area bg-1">
  <div class="container">
    <div class="cta-inner">
      <div class="row">
        <div class="col-lg-9 col-md-12">
          <div class="cta-content">
            <h2>Would you like to work with us?</h2>
            <p>
              We have a dedicated team of consultants who can help you with
              solutions based on your requirements. Talk to our experts and find
              out how we can be of help.
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-md-12">
          <div class="cta-btn-box">
            <a href="contact" class="btn btn-primary">Contact Us</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End CTA Area -->
