<section class="section services-section" id="services">
  <div class="container">
    <div class="row">
      <div class="section-title">
        <h2>{{ "expertise" | translate }}</h2>
      </div>
    </div>
    <app-messions></app-messions>

    <div class="row">
      <!-- feaure box -->
      <div class="col-sm-6 col-lg-4">
        <div class="feature-box-1">
          <div class="icon mr-35">
            <i class="fa fa-database"></i>
          </div>
          <div class="feature-content">
            <h3 class="text-align-center font-size-15">
              {{ "feature_boxes.data_pole.title" | translate }}
            </h3>
            <h5>{{ "feature_boxes.data_pole.subtitle" | translate }}</h5>
            <p
              [innerHTML]="'feature_boxes.data_pole.description' | translate"
            ></p>
          </div>
        </div>
      </div>
      <!-- / -->
      <!-- feaure box -->
      <div class="col-sm-6 col-lg-4">
        <div class="feature-box-1">
          <div class="icon mr-35">
            <i class="fa fa-gears"></i>
          </div>
          <div class="feature-content">
            <h3 class="text-align-center font-size-15">
              {{ "feature_boxes.devops_pole.title" | translate }}
            </h3>
            <h5>{{ "feature_boxes.devops_pole.subtitle" | translate }}</h5>
            <p
              [innerHTML]="'feature_boxes.devops_pole.description' | translate"
            ></p>
          </div>
        </div>
      </div>
      <!-- / -->
      <!-- feaure box -->
      <div class="col-sm-6 col-lg-4">
        <div class="feature-box-1">
          <div class="icon mr-35">
            <i class="fa fa-lock"></i>
          </div>
          <div class="feature-content">
            <h3 class="text-align-center font-size-15">
              {{ "feature_boxes.cybersecurity_pole.title" | translate }}
            </h3>
            <h5>
              {{ "feature_boxes.cybersecurity_pole.subtitle" | translate }}
            </h5>
            <p
              [innerHTML]="
                'feature_boxes.cybersecurity_pole.description' | translate
              "
            ></p>
          </div>
        </div>
      </div>
      <!-- / -->
      <!-- feaure box -->
      <div class="col-sm-6 col-lg-4">
        <div class="feature-box-1">
          <div class="icon mr-35">
            <i class="fa fa-graduation-cap"></i>
          </div>
          <div class="feature-content">
            <h3 class="text-align-center font-size-15">
              {{ "feature_boxes.training_pole.title" | translate }}
            </h3>
            <h5>{{ "feature_boxes.training_pole.subtitle" | translate }}</h5>
            <p
              [innerHTML]="
                'feature_boxes.training_pole.description' | translate
              "
            ></p>
          </div>
        </div>
      </div>
      <!-- / -->
      <!-- feaure box -->
      <div class="col-sm-6 col-lg-4">
        <div class="feature-box-1">
          <div class="icon mr-35">
            <i class="fa fa-headphones"></i>
          </div>
          <div class="feature-content">
            <h3 class="text-align-center font-size-15">{{"feature_boxes.support_pole.title" | translate }}</h3>
            <h5>{{"feature_boxes.support_pole.subtitle" | translate }}</h5>
            <p>
              {{"feature_boxes.support_pole.description" | translate }}
            </p>
          </div>
        </div>
      </div>
      <!-- / -->
      <!-- feaure box -->
      <!-- <div class="col-sm-6 col-lg-4">
        <div class="feature-box-1">
          <div class="icon mr-35">
            <i class="fa fa-cog"></i>
          </div>
          <div class="feature-content">
            <h3 class="text-align-center font-size-15">Data Department</h3>
            <h5>Ingestion, Processing , Analysis & Insights</h5>
            <p>
              I design and develop services for customers of all sizes,
              specializing in creating stylish, modern websites.
            </p>
          </div>
        </div>
      </div> -->
      <!-- / -->
    </div>
  </div>
</section>
