import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  lang: string = 'FR'; // Default language
  foot: boolean = true;
  title: string = 'K.A.Z';
  nav: any[] = [];

  nav1: any[] = [
    { lg: "Accueil", route: "/homefr", liste: [] },
    { lg: "Services", route: "/services", liste: [] },
    { lg: "Références", route: "/reference", liste: [] },
    { lg: "Formations", route: "/training", liste: [{ id: "Microsoft Azure", route: "/training1" }, { id: "DevOps", route: "/training2" }] },
    { lg: "Carrières", route: "/joinus", liste: [] },
    { lg: "A propos", route: "/about", liste: [] },
    { lg: "Contact", route: "/contact", liste: [] }
  ];

  nav2: any[] = [
    { lg: "Home", route: "/homefr", liste: [] },
    { lg: "Services", route: "/services", liste: [] },
    { lg: "References", route: "/reference", liste: [] },
    { lg: "Training", route: "/training", liste: [{ id: "Microsoft Azure", route: "/training1" }, { id: "DevOps", route: "/training2" }] },
    { lg: "Careers", route: "/joinus", liste: [] },
    { lg: "About", route: "/about", liste: [] },
    { lg: "Contact", route: "/contact", liste: [] }
  ];

  constructor(private router: Router, private translate: TranslateService) { }

  isDropdownOpen = false;
  currentLanguage = 'FR'; // Initial language
  currentImage = 'assets/images/fr.svg'; // Initial image source
  dropdownImages = {
    FR: 'assets/images/fr.svg',
    EN: 'assets/images/en.svg'
  };

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  swapLanguage(language: string) {
    if (this.currentLanguage !== language) {
      this.currentLanguage = language;
      this.currentImage = this.dropdownImages[language];
    }
    this.toggleDropdown();

    this.lang = language;
    this.translate.use(this.lang);

    // Update navigation and footer based on selected language
    this.nav = this.lang === 'EN' ? this.nav2 : this.nav1;
    this.foot = this.lang === 'EN' ? false : true;

    // Save language preference to local storage
    localStorage.setItem("lang", this.lang);
  }

  ngOnInit() {
    // Get language from local storage or default to 'FR'
    const storedLang = localStorage.getItem("lang") || 'FR';
    this.lang = storedLang;
    this.translate.use(this.lang);

    // Set navigation based on language
    this.nav = this.lang === 'EN' ? this.nav2 : this.nav1;
    this.foot = this.lang === 'EN' ? false : true;
  }

  switchLanguage(event: any) {
    const selectedLang = event.target.value;
    this.lang = selectedLang;
    this.translate.use(this.lang);

    // Update navigation and footer based on selected language
    this.nav = this.lang === 'EN' ? this.nav2 : this.nav1;
    this.foot = this.lang === 'EN' ? false : true;

    // Save language preference to local storage
    localStorage.setItem("lang", this.lang);

    // Navigate to the appropriate route
    //this.router.navigate([this.lang === 'EN' ? '/homefr' : '/homefr']);
  }
}
