<!-- Start Page Title Area -->
<div class="page-title-area item-bg3">
  <div class="container">
    <div class="page-title-content">
      <h2>{{ "careersPageTitle.careers" | translate }}</h2>
    </div>
  </div>

  <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-110">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="about-content">
          <h2 class="text-">Work @ CloudiaSys</h2>
          <p>
            {{ "careers_about.recruitment" | translate }}
          </p>
          <p>
            {{ "careers_about.experience" | translate }}
          </p>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="about-image">
          <img
            src="assets/images/joinus.png"
            alt="image"
            style="border-radius: 4px"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="shape-img1"><img src="assets/img/shape/1.png" alt="image" /></div>
  <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image" /></div>
  <div class="shape-img3"><img src="assets/img/shape/3.png" alt="image" /></div>
  <div class="shape-img4"><img src="assets/img/shape/4.svg" alt="image" /></div>
  <div class="shape-img5"><img src="assets/img/shape/5.svg" alt="image" /></div>
  <div class="shape-img6"><img src="assets/img/shape/6.png" alt="image" /></div>
  <div class="dot-shape1">
    <img src="assets/img/shape/dot1.png" alt="image" />
  </div>
  <div class="dot-shape2">
    <img src="assets/img/shape/dot3.png" alt="image" />
  </div>
</section>
<!-- End About Area -->

<!-- Start Why Work at Area -->
<section class="services-area">
  <div class="container">
    <div class="section-title">
      <h2>{{ "whyWorkAt.title" | translate }}</h2>
    </div>

    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-services-box">
          <div class="icon">
            <i class="flaticon-income"></i>
          </div>
          <h3>
            <a href="#">
              {{ "whyWorkAt.innovationExperience.title" | translate }}
            </a>
          </h3>
          <p>
            {{ "whyWorkAt.innovationExperience.description" | translate }}
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-services-box">
          <div class="icon">
            <i class="flaticon-automatic"></i>
          </div>
          <h3>
            <a href="#"
              >{{ "whyWorkAt.integrityCulture.title" | translate }}
            </a>
          </h3>
          <p>
            {{ "whyWorkAt.integrityCulture.description" | translate }}
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-services-box">
          <div class="icon">
            <i class="flaticon-locked"></i>
          </div>
          <h3><a href="#">{{ "whyWorkAt.careerRoadmap.title" | translate }} </a></h3>
          <p>
            {{ "whyWorkAt.careerRoadmap.description" | translate }} 
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="shape-img2"><img src="assets/img/shape/2.svg" alt="image" /></div>
  <div class="shape-img3"><img src="assets/img/shape/3.png" alt="image" /></div>
  <div class="shape-img4"><img src="assets/img/shape/4.svg" alt="image" /></div>
  <div class="shape-img5"><img src="assets/img/shape/5.svg" alt="image" /></div>
  <div class="shape-img7"><img src="assets/img/shape/3.png" alt="image" /></div>
  <div class="dot-shape1">
    <img src="assets/img/shape/dot1.png" alt="image" />
  </div>
  <div class="dot-shape2">
    <img src="assets/img/shape/dot3.png" alt="image" />
  </div>
  <div class="dot-shape4">
    <img src="assets/img/shape/dot4.png" alt="image" />
  </div>
  <div class="dot-shape5">
    <img src="assets/img/shape/dot5.png" alt="image" />
  </div>
  <div class="dot-shape6">
    <img src="assets/img/shape/dot6.png" alt="image" />
  </div>
</section>
<!-- End Why Work at Area -->

<!-- Start Free Trial Area -->
<section class="free-trial-area">
  <div class="row m-0">
    <div class="col-lg-6 col-md-12 p-0">
      <div class="free-trial-image">
        <img src="assets/images/workspace.png" alt="image" />
      </div>
    </div>

    <div class="col-lg-6 col-md-12 p-0">
      <div class="free-trial-content challenge">
        <h2>{{"freeTrial.joinUs" | translate}}</h2>
        <p>
          {{"freeTrial.applyNow" | translate }} 
          <b>RH@CLAUDIASYS.COM</b>
        </p>
      </div>
    </div>
  </div>
</section>
<!-- End Free Trial Area -->
