<!-- Start Page Title Area -->
<div class="page-title-area item-bg6">
  <div class="container">
    <div class="page-title-content">
      <h2 [innerHTML]="'Training2Area.title' | translate"></h2>
      <p
        class="text-white font-weight-bold"
        [innerHTML]="'Training2Area.description1' | translate"
      ></p>
      <p
        class="text-white font-weight-bold"
        [innerHTML]="'Training2Area.description2' | translate"
      ></p>
    </div>
  </div>
  <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<section class="blog-details-area ptb-110">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-12">
        <h1>{{ "presentation.title" | translate }}</h1>
        <h2>{{ "presentation.objectives.title" | translate }}</h2>
        <p class="text-left">
          {{ "presentation.objectives.item1" | translate }}
        </p>
        <p class="text-left">
          {{ "presentation.objectives.item2" | translate }}
        </p>
        <p class="text-left">
          {{ "presentation.objectives.item3" | translate }}
        </p>
        <p class="text-left">
          {{ "presentation.objectives.item4" | translate }}
        </p>
        <p class="text-left">
          {{ "presentation.objectives.item5" | translate }}
        </p>
        <p class="text-left">
          {{ "presentation.objectives.item6" | translate }}
        </p>
        <p class="text-left">
          {{ "presentation.objectives.item7" | translate }}
        </p>
        <p class="text-left">
          {{ "presentation.objectives.item8" | translate }}
        </p>

        <h1>{{ "presentation.program.title" | translate }}</h1>

        <b>{{ "presentation.program.module1.title" | translate }}</b>
        <br />
        <ul>
          <li>{{ "presentation.program.module1.item1" | translate }}</li>
          <li>{{ "presentation.program.module1.item2" | translate }}</li>
          <li>
            {{ "presentation.program.module1.item3" | translate }}
          </li>
          <li>{{ "presentation.program.module1.item4" | translate }}</li>
        </ul>

        <b>{{ "presentation.program.module2.title" | translate }}</b>
        <br />
        <ul>
          <li
            [innerHTML]="'presentation.program.module2.item1' | translate"
          ></li>
          <li
            [innerHTML]="'presentation.program.module2.item2' | translate"
          ></li>
          <li
            [innerHTML]="'presentation.program.module2.item3' | translate"
          ></li>
          <li
            [innerHTML]="'presentation.program.module2.item4' | translate"
          ></li>
        </ul>

        <b>{{ "presentation.program.module3.title" | translate }}</b>
        <br />
        <ul>
          <li>{{ "presentation.program.module3.item1" | translate }}</li>
          <li>{{ "presentation.program.module3.item2" | translate }}</li>
          <li
            [innerHTML]="'presentation.program.module3.item3' | translate"
          ></li>
          <li
            [innerHTML]="'presentation.program.module3.item4' | translate"
          ></li>
        </ul>

        <b>{{ "presentation.program.module4.title" | translate }}</b>
        <br />
        <ul>
          <li
            [innerHTML]="'presentation.program.module4.item1' | translate"
          ></li>
          <li
            [innerHTML]="'presentation.program.module4.item2' | translate"
          ></li>
          <li
            [innerHTML]="'presentation.program.module4.item3' | translate"
          ></li>
          <li
            [innerHTML]="'presentation.program.module4.item4' | translate"
          ></li>
        </ul>

        <b>{{ "presentation.program.module5.title" | translate }}</b>
        <br />
        <ul>
          <li>{{ "presentation.program.module2.item1" | translate }}</li>
          <li>{{ "presentation.program.module2.item2" | translate }}</li>
          <li
            [innerHTML]="'presentation.program.module5.item3' | translate"
          ></li>
          <li
            [innerHTML]="'presentation.program.module5.item4' | translate"
          ></li>
        </ul>

        <b>{{ "presentation.program.module6.title" | translate }}</b>
        <br />
        <ul>
          <li
            [innerHTML]="'presentation.program.module6.item1' | translate"
          ></li>
          <li
            [innerHTML]="'presentation.program.module6.item2' | translate"
          ></li>
          <li
            [innerHTML]="'presentation.program.module6.item3' | translate"
          ></li>
          <li
            [innerHTML]="'presentation.program.module6.item4' | translate"
          ></li>
          <li
            [innerHTML]="'presentation.program.module6.item5' | translate"
          ></li>
        </ul>

        <b>{{ "presentation.program.module7.title" | translate }}</b>
        <br />
        <ul>
          <li
            [innerHTML]="'presentation.program.module7.item1' | translate"
          ></li>
          <li
            [innerHTML]="'presentation.program.module7.item2' | translate"
          ></li>
          <li
            [innerHTML]="'presentation.program.module7.item3' | translate"
          ></li>
          <li
            [innerHTML]="'presentation.program.module7.item4' | translate"
          ></li>
        </ul>

        <b>{{ "presentation.program.module8.title" | translate }}</b>
        <br />
        <ul>
          <li
            [innerHTML]="'presentation.program.module8.item1' | translate"
          ></li>
          <li
            [innerHTML]="'presentation.program.module8.item2' | translate"
          ></li>
          <li
            [innerHTML]="'presentation.program.module8.item3' | translate"
          ></li>
        </ul>

        <b>{{ "presentation.program.module9.title" | translate }}</b>
        <br />
        <ul>
          <li
            [innerHTML]="'presentation.program.module9.item1' | translate"
          ></li>
          <li
            [innerHTML]="'presentation.program.module9.item2' | translate"
          ></li>
          <li
            [innerHTML]="'presentation.program.module9.item3' | translate"
          ></li>
          <li
            [innerHTML]="'presentation.program.module9.item4' | translate"
          ></li>
        </ul>

        <b>{{ "presentation.program.module10.title" | translate }}</b>
        <br />
        <ul>
          <li
            [innerHTML]="'presentation.program.module10.item1' | translate"
          ></li>
          <li
            [innerHTML]="'presentation.program.module9.item2' | translate"
          ></li>
          <li
            [innerHTML]="'presentation.program.module9.item3' | translate"
          ></li>
          <li
            [innerHTML]="'presentation.program.module9.item4' | translate"
          ></li>
        </ul>

        <h2>{{ "presentation.prerequisites.title" | translate }}</h2>
        <p
          class="text-left"
          [innerHTML]="'presentation.prerequisites.item1' | translate"
        ></p>

        <h2>{{ "presentation.audience.title" | translate }}</h2>
        <p
          class="text-left"
          [innerHTML]="'presentation.audience.item1' | translate"
        ></p>
      </div>
    </div>
  </div>
</section>
<!-- End Blog Details Area -->
