import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-key-numbers',
  templateUrl: './keynumbers.component.html',
  styleUrls: ['./keynumbers.component.css'],
})
export class KeyNumbersComponent {
    @ViewChild('keyNumbersSection') keyNumbersSection!: ElementRef;

    ngAfterViewInit(): void {
      this.initIntersectionObserver();
    }
  
    private initIntersectionObserver() {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          this.initCounters();
         
          observer.unobserve(this.keyNumbersSection.nativeElement);
        }
      }, {
        threshold: 0.1 // Adjust the threshold as needed
      });
  
      observer.observe(this.keyNumbersSection.nativeElement);
    }
  
    private initCounters() {
      this.animateCounter('employes', 30, false); // Pass false to avoid $ sign
      this.animateCounter('chiffre-affaires', 1500000, true); 
      this.animateCounter('experts', 3, false);
    }
  
    private animateCounter(id: string, target: number, withDollar: boolean) {
      const element = document.getElementById(id);
      if (element) {
        let count = 0;
        const interval = setInterval(() => {
          count += Math.ceil(target / 100); // Increment by a portion of the target
          if (count >= target) {
            count = target;
            clearInterval(interval);
          }
          element.textContent = this.formatNumber(count, withDollar);
        }, 60); // Adjust the interval time for smoother or faster animation
      }
    }
  
    private formatNumber(value: number, withDollar: boolean): string {
      let formattedValue: string;
      if (value >= 1000000) {
        // Format number in millions
        formattedValue = (value / 1000000).toFixed(1) + 'M';
      } else if (value >= 1000) {
        // Format number in thousands
        formattedValue = (value / 1000).toFixed(1) + 'K';
      } else {
        formattedValue = value.toString();
      }
      return withDollar ? formattedValue + '€' : formattedValue;
    }
}
