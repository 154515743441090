

   
      
      <!--########################### About US Starts Here ###################### -->
      
      <div id="about_us" class="about-us container-fluid">
         <div class="container">
             <div class="session-title row">
                  <h2>About Me</h2>
                  <p>Mes propres informations personnels et professionnels</p>
                  <div class="heading-line"></div>
              </div>
              <div class="about-row row">
                 <div class="image-col col-md-4">
                     <img src="assets/images/about.png" alt="">
                     <button class="btn btn-primary"><i class="fas fa-download"></i> Upload photo</button>
                 </div>
                 <div class="detail-col col-md-8">
                     <h2>John Smith</h2>
                      <h6>Freelance Web Designer</h6>
                      <p>There are many variations of passages of Lorem Ipsum available but the majority is have suffered alteration in that some form by injected humour or randomised that words which don't look even slightly believable. If you are going a to use a passage of Lorem Ipsum you need to be sure there isn't anything embarrassing. There are is many variations of passages available.</p>
                        <div class="row">
                          <div class="col-md-6 col-12">
                              <div class="info-list">
                                  <ul>
                                      <li><span>Birthday:</span>05. 09.1987</li>
                                      <li><span>City:</span>Toranto</li>
                                      <li><span>Study:</span>Cambridge University</li>
                                      <li><span>Website:</span>www.smarteyeapps.com</li>
                                  </ul>
                              </div>
                          </div>
                          <div class="col-md-6 col-12">
                              <div class="info-list">
                                  <ul>
                                      <li><span>Age:</span>31 Years</li>
                                      <li><span>Degree:</span>Master</li>
                                      <li><span>Mail:</span>email@example.com</li>
                                      <li><span>Phone:</span>+01 454 548 4458</li>
                                  </ul>
                              </div>
                          </div>
                         
                      </div>
                     <br>
                      <button class="btn btn-primary" style="margin-left: 700px;"><i class="fas fa-edit"></i> Edit</button>
                 </div>
                  
                 
                
              </div>
         </div>    
          
      </div>
      
         <!--########################### Services Starts Here ###################### -->
         
        <div id="services"  class="services container-fluid">
         <div class="container">
             <div class="session-title row">
                  <h2>Experience</h2>
                  <p>mes experiences professionnels</p>
                 
                  <div class="heading-line"></div>
              </div>
              <div class="services-row row">
                  <div class="col-md-4 seric-div">
                      <div class="service-card">
                     
                          <i class="far fa-file-image"></i>
                          <h4>Graphic Design</h4>
                       
                          
                            <p><span>Entreprise :  &nbsp;&nbsp;</span>The team consulting</p>
                            <p><span>Date debut :  &nbsp;&nbsp; </span>05. 09.2019</p>
                            <p><span>Date fin :&nbsp;&nbsp; </span>05. 12.2019</p>
                            <p><span>Taches réalises : &nbsp;&nbsp;</span>Developpement des interfaces avec Angular</p>
                            
                           
                        </div>
                        
                  </div>
                  <div class="col-md-4 seric-div">
                      <div class="service-card">
                         <i class="fas fa-desktop"></i>
                          <h4>Web Development</h4>
                          <p><span>Entreprise :  &nbsp;&nbsp;</span>The team consulting</p>
                            <p><span>Date debut :  &nbsp;&nbsp; </span>05. 09.2019</p>
                            <p><span>Date fin :&nbsp;&nbsp; </span>05. 12.2019</p>
                            <p><span>Taches réalises : &nbsp;&nbsp;</span>Developpement des interfaces avec Angular</p>
                      </div>
                  </div>
                  <div class="col-md-4 seric-div">
                      <div class="service-card">
                          <i class="fas fa-bullhorn"></i>
                          <h4>Digital Marketing</h4>
                          <p><span>Entreprise :  &nbsp;&nbsp;</span>The team consulting</p>
                            <p><span>Date debut :  &nbsp;&nbsp; </span>05. 09.2019</p>
                            <p><span>Date fin :&nbsp;&nbsp; </span>05. 12.2019</p>
                            <p><span>Taches réalises : &nbsp;&nbsp;</span>Developpement des interfaces avec Angular</p>
                      </div>
                  </div>
                  <div class="col-md-4 seric-div">
                      <div class="service-card">
                          <i class="fab fa-uikit"></i>
                          <h4>UI/UX Design</h4>
                          <p>Lorem ipsum dolor sit aconsect dipisicing elit, sed do eiusmod to incididunt uabore etdolore magna aliqua.</p>
                      </div>
                  </div>
                  <div class="col-md-4 seric-div">
                      <div class="service-card">
                         <i class="fas fa-suitcase"></i>
                          <h4>Business Analysis</h4>
                          <p>Lorem ipsum dolor sit aconsect dipisicing elit, sed do eiusmod to incididunt uabore etdolore magna aliqua.</p>
                      </div>
                  </div>
                  <div class="col-md-4 seric-div">
                      <div class="service-card">
                          <i class="fas fa-mobile-alt"></i>
                          <h4>Mobile App Development</h4>
                          <p>Lorem ipsum dolor sit aconsect dipisicing elit, sed do eiusmod to incididunt uabore etdolore magna aliqua.</p>
                      </div>
                  </div>
              </div>
              <button class="btn btn-primary" style="margin-left: 950px;"><i class="fas fa-edit"></i> Edit</button>
            </div>
      </div>
             
     <!-- ########################### Skills Starts Here ###################### -->
       
      <div id="skills" class="skills container-fluid">
          <div class="row">
              <div class="col-md-6 skii-div tech-skill">
                  <div class="title">
                      <h2>Technical Skill</h2>
                      <p>Mes competances techniques</p>
                  </div>                
                  <ul>
                      <li>
                          <label for="">UI/UX Designer</label>
                              <div class="progress">
                                <div class="progress-bar bg-warning" role="progressbar" style="width: 95%" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                      </li>
                       <li>
                          <label for="">JavaScript</label>
                              <div class="progress">
                                <div class="progress-bar bg-warning" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                      </li>
                       <li>
                          <label for="">HTML5</label>
                              <div class="progress">
                                <div class="progress-bar bg-warning" role="progressbar" style="width: 55%" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                      </li>
                       <li>
                          <label for="">PHP / MYSQL</label>
                              <div class="progress">
                                <div class="progress-bar bg-warning" role="progressbar" style="width: 65%" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                      </li>
                       
                  </ul>
                  <button class="btn btn-primary" style="margin-left: 450px;"><i class="fas fa-edit"></i> Edit</button>
              </div>
              <div class="col-md-6 skii-div pro-skill">
                  <div class="title">
                      <h2>Technical Skill</h2>
                      <p>Professional Skills</p>                     
                  </div>
                   <ul>
                      <li>
                          <label for="">Photoshop</label>
                          <div class="progress">
                            <div class="progress-bar bg-warning progress-bar-striped" role="progressbar" style="width: 90%" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                      </li>
                       <li>
                          <label for="">Illustrator</label>
                          <div class="progress">
                            <div class="progress-bar bg-warning progress-bar-striped" role="progressbar" style="width: 80%" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                      </li>
                       <li>
                          <label for="">AngularJS</label>
                          <div class="progress">
                            <div class="progress-bar bg-warning progress-bar-striped" role="progressbar" style="width: 60%" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                      </li>
                       <li>
                          <label for="">PHP</label>
                          <div class="progress">
                            <div class="progress-bar bg-warning progress-bar-striped" role="progressbar" style="width: 70%" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                      </li>
                  </ul>

                  <button class="btn btn-primary" style="margin-left: 450px;"><i class="fas fa-edit"></i> Edit</button>
              </div>
          </div>
      </div>  
             

  <!--*************** Pricing Starts Here ***************-->
  <div id="generic_price_table">
    <section class="container-fluid price-tab">
        <div class="container">
             <div class="row session-title">
                <h2>Etude</h2>
                <p>Mon parcours academique</p>
            </div>
        </div>
        <div class="container">

            <!--BLOCK ROW START-->
            <div class="row price-row">
                <div class="col-md-4">

                    <!--PRICE CONTENT START-->
                    <div class="generic_content clearfix nnj">

                        <!--HEAD PRICE DETAIL START-->
                        <div class="generic_head_price clearfix">

                            <!--HEAD CONTENT START-->
                            <div class="generic_head_content clearfix">

                                <!--HEAD START-->
                                <div class="head_bg"></div>
                                <div class="head">
                                    <span>Licence</span>
                                </div>
                                <!--//HEAD END-->

                            </div>
                            <!--//HEAD CONTENT END-->

                        </div>
                        <!--//HEAD PRICE DETAIL END-->

                        <!--FEATURE LIST START-->
                        <div class="generic_feature_list">
                            <ul>
                                <li><span>University</span> ULT</li>
                                <li><span>Degree </span> Storage</li>
                                <li><span>Start Year </span> Accounts</li>
                                <li><span>Start Year</span> Host Domain</li>
                              
                            </ul>
                        </div>
                        <!--//FEATURE LIST END-->

                        <!--BUTTON START-->
                        <div class="generic_price_btn clearfix">
                            <a class="" href="">Edit</a>
                        </div>
                        <!--//BUTTON END-->

                    </div>
                    <!--//PRICE CONTENT END-->

                </div>

                <div class="col-md-4">

                    <!--PRICE CONTENT START-->
                    <div class="generic_content active clearfix nnj">

                        <!--HEAD PRICE DETAIL START-->
                        <div class="generic_head_price clearfix">

                            <!--HEAD CONTENT START-->
                            <div class="generic_head_content clearfix">

                                <!--HEAD START-->
                                <div class="head_bg"></div>
                                <div class="head">
                                    <span>Master</span>
                                </div>
                                <!--//HEAD END-->

                            </div>
                            <!--//HEAD CONTENT END-->
                        </div>
                        <!--//HEAD PRICE DETAIL END-->

                        <!--FEATURE LIST START-->
                        <div class="generic_feature_list">
                            <ul>
                                <li><span>University</span> ULT</li>
                                <li><span>Degree </span> Storage</li>
                                <li><span>Start Year </span> Accounts</li>
                                <li><span>Start Year</span> Host Domain</li>
                              
                            </ul>
                        </div>
                        <!--//FEATURE LIST END-->

                        <!--BUTTON START-->
                        <div class="generic_price_btn clearfix">
                            <a class="" href="">Edit</a>
                        </div>
                        <!--//BUTTON END-->

                    </div>
                    <!--//PRICE CONTENT END-->

                </div>
                <div class="col-md-4">

                    <!--PRICE CONTENT START-->
                    <div class="generic_content clearfix nnj">

                        <!--HEAD PRICE DETAIL START-->
                        <div class="generic_head_price clearfix">

                            <!--HEAD CONTENT START-->
                            <div class="generic_head_content clearfix">

                                <!--HEAD START-->
                                <div class="head_bg"></div>
                                <div class="head">
                                    <span>Ingenierie</span>
                                </div>
                                <!--//HEAD END-->

                            </div>
                            <!--//HEAD CONTENT END-->
                        </div>
                        <!--//HEAD PRICE DETAIL END-->

                        <!--FEATURE LIST START-->
                        <div class="generic_feature_list">
                            <ul>
                                <li><span>University</span> ULT</li>
                                <li><span>Degree </span> Storage</li>
                                <li><span>Start Year </span> Accounts</li>
                                <li><span>Start Year</span> Host Domain</li>
                              
                            </ul>
                        </div>
                        <!--//FEATURE LIST END-->

                        <!--BUTTON START-->
                        <div class="generic_price_btn clearfix">
                            <a class="" href="">Edit</a>
                        </div>
                        <!--//BUTTON END-->

                    </div>
                    <!--//PRICE CONTENT END-->

                </div>
            </div>
            <!--//BLOCK ROW END-->

        </div>
    </section>

</div>

       <!--  ************************* Testimonial  Starts Here ************************** -->

   <section id="testimonial" class="customer-serv">
          <div class="container">
              <div class="row session-title">
                  <h2>Professionnel Info</h2>
                 
              </div>
  
              <div class="row ro-clo">
                  <div data-anijs="if: scroll, on: window, do: bounceInLeft animated, before: scrollReveal" class="col-12  col-md-6 col-lg-4">
                      
                      <div class="cust-det row">
                          <div class="col-sm-3 col-3 img-circl">
                              <img alt="" src="assets/images/testimonial/linkedin.png">
                             
                          </div>
                          <div class="col-sm-5 col-5 an-mtc no-padding">
                              <b>Sarath Ali</b>
                              <p>(Sales Agent)</p>
                          </div>
                          <div class="col-sm-4 col-4 star-par">
                              <ul class="stars">
                                  <li><i class="fa fa-star"></i></li>
                                  <li><i class="fa fa-star"></i></li>
                                  <li><i class="fa fa-star"></i></li>
                                  <li><i class="fa fa-star"></i></li>
                                  <li><i class="fa fa-star"></i></li>
                              </ul>
                          </div>
                      </div>
                  </div>
                  <div class="col-12  col-md-6 col-lg-4">
                      
                      <div class="cust-det row">
                          <div class="col-sm-3 col-3 img-circl">
                              <img alt="" src="assets/images/testimonial/github-image.png">
                          </div>
                          <div class="col-sm-5 col-5 an-mtc no-padding">
                              <b>Binny Aderson</b>
                              <p>(Sales Agent)</p>
                          </div>
                          <div class="col-sm-4  col-4 star-par">
                              <ul class="stars">
                                  <li><i class="fa fa-star"></i></li>
                                  <li><i class="fa fa-star"></i></li>
                                  <li><i class="fa fa-star"></i></li>
                                  <li><i class="fa fa-star"></i></li>
                                  <li><i class="fa fa-star"></i></li>
                              </ul>
                          </div>
                      </div>
                  </div>
                  <div data-anijs="if: scroll, on: window, do: bounceInRight animated, before: scrollReveal" class="col-12  col-md-6 col-lg-4">
                     
                      <div class="cust-det row">
                          <div class="col-sm-3 col-3 img-circl">
                              <img alt="" src="assets/images/testimonial/curriculum.png">
                          </div>
                          <div class="col-sm-5 col-5 an-mtc no-padding">
                              <b>Arjun Samluel</b>
                              <p>(Sales Agent)</p>
                          </div>
                          <div class="col-sm-4 col-4 star-par">
                              <ul class="stars">
                                  <li><i class="fa fa-star"></i></li>
                                  <li><i class="fa fa-star"></i></li>
                                  <li><i class="fa fa-star"></i></li>
                                  <li><i class="fa fa-star"></i></li>
                                  <li><i class="fa fa-star"></i></li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
  
              <button class="btn btn-primary" style="margin-left: 950px;"><i class="fas fa-edit"></i> Edit</button>
          </div>
  
      </section>   
            
  
            
 




