import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { AppComponent } from './app.component';
import { AlertComponent } from './components/alert.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JwtInterceptor, ErrorInterceptor } from './helpers';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { RegisterEntrepriseComponent } from './register-entreprise/register-entreprise.component';
import { ProfileComponent } from './profile/profile.component';
import { ServicesfrComponent } from './servicesfr/servicesfr.component';
import { KeyNumbersComponent } from './components/KeyNumbers/keynumbers.component';
import { HomefrComponent } from './homefr/homefr.component';
import { FeaturesComponent } from './components/featuers/features.component';
import { PartnersComponent } from './components/Partners/partners.component';
import { MessionComponent } from './components/mession/mession.component';
import { ReferncesComponent } from './reference/reference.component';
import { AmbitionComponent } from './components/ambition/ambition.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TrainingComponent } from './training/training.component';
import { Training1Component } from './training1/training1.component';
import { Training2Component } from './training2/training2.component';
import { JoinusComponent } from './joinus/joinus.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    // CommonModule,
    // routing,
    // RouterModule,
    AppRoutingModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [
    AppComponent,
    AlertComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    ConfirmEmailComponent,
    RegisterEntrepriseComponent,
    ProfileComponent,
    ServicesfrComponent,
    KeyNumbersComponent,
    HomefrComponent,
    FeaturesComponent,
    PartnersComponent,
    MessionComponent,
    ReferncesComponent,
    AmbitionComponent,
    TrainingComponent,
    Training1Component,
    Training2Component,
    JoinusComponent,
    AboutComponent,
    ContactComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
    //fakeBackendProvider
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
