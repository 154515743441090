import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {
  constructor() {}
  valid = false;

  ngOnInit() {
    this.valid = true;
  }

  f() {
    this.valid = !this.valid;
  }
}
