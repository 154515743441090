<section class="services-area ptb-110" id="servicesArea">
  <section class="container">
    <div class="section-title">
      <h2>{{"ambitions_section.section_title" | translate}}</h2>
      <!-- <p>
        L’équipe est structurée en 3 pôles d’expertise : conseil en Data
        Science,Machine Learning et Business Intelligence.
      </p> -->
    </div>
    <div #AmbitionSection class="container-xxl py-5">
      <div class="container py-5">
        <div class="row g-5">
          <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
            <!-- <h6 class="text-secondary text-uppercase mb-3">
              Quelques Chiffres
            </h6> -->
            <h1 class="mb-4">
              {{"ambitions_section.headline" | translate}}
            </h1>
            <p class="mb-5">
              {{"ambitions_section.description" | translate}}
            </p>
            <!-- <div class="d-flex align-items-center">
            <i
              class="fa fa-headphones fa-2x flex-shrink-0 bg-primary p-3 text-white"
            ></i>
            <div class="ps-4">
              <h6>Appelez pour toute question !</h6>
              <h3 class="text-primary m-0">+012 345 6789</h3>
            </div>
          </div> -->
          </div>
          <div class="col-lg-6">
            <div class="row g-4 align-items-center">
              <div class="col-sm-6">
                <div
                  class="bg-warning border-raduis-75 p-4 mb-4 wow fadeIn" data-wow-delay="0.3s"
                >
                  <i class="fa fa-users fa-2x text-white mb-3 ml-40"></i>
                  <h2 class="text-white mb-2 text-align-center" id="employes">40</h2>
                  <p class="text-white counter-label text-align-center">{{"ambitions_section.employees" | translate}}</p>
                </div>
                <div class="bg-danger border-raduis-75 p-4 wow fadeIn" data-wow-delay="0.5s">
                  <i class="fa fa-dollar-sign fa-2x text-white mb-3 ml-40"></i>
                  <h2 class="text-white mb-2 text-align-center" id="chiffre-affaires">2.5M$</h2>
                  <p class="text-white counter-label text-align-center">{{"ambitions_section.revenue" | translate}}</p>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="p-4 wow fadeIn" data-wow-delay="0.7s">
                  <img
                    src="../../../assets/img/cloudiasys_academy.png"
                    alt=""
                  />

                  <!-- <h2 class="text-white mb-2" id="experts">0</h2>
                  <p class="text-white counter-label">Experts</p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
