import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css'],
})
export class FeaturesComponent implements OnInit {
  description: SafeHtml;
  constructor(private translate: TranslateService,private sanitizer: DomSanitizer) {
    this.translate.get('feature_boxes.data_pole.description').subscribe((res: string) => {
      this.description = this.sanitizer.bypassSecurityTrustHtml(res);
    });
  }

  ngOnInit() {
// Set the default language or use the stored language
const lang = localStorage.getItem('lang') || 'FR';
this.translate.use(lang);  }
}