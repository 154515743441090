import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'app-messions',
  templateUrl: './mession.component.html',
  styleUrls: ['./mession.component.css'],
})
export class MessionComponent implements OnInit {
  constructor() {}

  @ViewChild('poledataTitle', { static: false }) poledataTitle: ElementRef;
  @ViewChild('poledevopsTitle', { static: false }) poledevopsTitle: ElementRef;
  @ViewChild('polecyberTitle', { static: false }) polecyberTitle: ElementRef;
  @ViewChild('poleformationTitle', { static: false }) poleformationTitle: ElementRef;
  @ViewChild('polesupportTitle', { static: false }) polesupportTitle: ElementRef;

  scrollToDataPole() {
    const element = document.getElementById('poledataTitle');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  scrollToDevOpsPole() {
    const element = document.getElementById('poledevopsTitle');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } 
  }

  scrollToCyberPole() {
    const element = document.getElementById('polecyberTitle');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  scrollToFormationPole() {
    const element = document.getElementById('poleformationTitle');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  scrollToSupportPole() {
    const element = document.getElementById('polesupportTitle');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  ngOnInit() {}
}
