import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeyNumbersComponent } from '../components/KeyNumbers/keynumbers.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-homefr',
  templateUrl: './homefr.component.html',
  styleUrls: ['./homefr.component.css'],

})
export class HomefrComponent implements OnInit {
  constructor(public router: Router,private translate: TranslateService) {}

  ngOnInit() {
// Set the default language or use the stored language
const lang = localStorage.getItem('lang') || 'FR';
this.translate.use(lang);  }
}
