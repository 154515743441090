<!-- Start Navbar Area -->
<div class="navbar-area">
  <div class="evolta-responsive-nav">
    <div class="container">
      <div class="evolta-responsive-menu mobile-container">
        <div class="logo">
          <a>
            <img src="assets/img/white-logo.png" class="main-logo" alt="logo" />
            <img src="assets/img/black-logo.png" class="optional-logo" alt="logo" />
          </a>
        </div>
        <div id="cloudia-language" [ngClass]="{'cloudia-opened': isDropdownOpen}">
          <button id="cloudia-language-trigger" (click)="toggleDropdown()">
            <img [src]="currentImage" alt="Language">
          </button>
          <div id="cloudia-language-ddown" *ngIf="isDropdownOpen">
            <a *ngIf="currentLanguage !== 'FR'" (click)="swapLanguage('FR')">
              <img src="assets/images/fr.svg" alt="Français">
            </a>
            <a *ngIf="currentLanguage !== 'EN'" (click)="swapLanguage('EN')">
              <img src="assets/images/en.svg" alt="English">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="evolta-nav">
    <div class="container-fluid">
      <nav class="navbar navbar-expand-md navbar-light">
        <a class="navbar-brand">
          <img src="assets/img/white-logo.png" class="main-logo" alt="logo" />
          <img src="assets/img/black-logo.png" class="optional-logo" alt="logo" />
        </a>
        <div class="collapse navbar-collapse mean-menu">
          <ul class="navbar-nav">
            <li class="nav-item" *ngFor="let navi of nav; let i = index">
              <a routerLink="{{ navi.route }}" class="nav-link">{{ navi.lg }}</a>
              <ul class="dropdown-menu" *ngIf="navi.liste.length > 0">
                <li class="nav-item" *ngFor="let naviga of navi.liste">
                  <a routerLink="{{ naviga.route }}" class="nav-link">{{ naviga.id }}</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div id="cloudia-language" [ngClass]="{'cloudia-opened': isDropdownOpen}">
          <button id="cloudia-language-trigger" (click)="toggleDropdown()">
            <img [src]="currentImage" alt="Language">
          </button>
          <div id="cloudia-language-ddown" *ngIf="isDropdownOpen">
            <a *ngIf="currentLanguage !== 'FR'" (click)="swapLanguage('FR')">
              <img src="assets/images/fr.svg" alt="Français">
            </a>
            <a *ngIf="currentLanguage !== 'EN'" (click)="swapLanguage('EN')">
              <img src="assets/images/en.svg" alt="English">
            </a>
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>
<!-- End Navbar Area -->

<router-outlet></router-outlet>

<!-- Start Footer FR Area -->
<footer class="footer-area" *ngIf="foot">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-footer-widget">
          <div class="logo">
            <a routerLink="/homefr"><img src="assets/img/white-logo.png" alt="image" /></a>
            <p>
              Afin de mieux répondre aux besoins de ses clients CloudiaSys
              incarne l’esprit de la valorisation des données.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-footer-widget">
          <h3>Services</h3>
          <ul class="footer-services-list">
            <li><a href="#">Data Science</a></li>
            <li><a href="#">Machine Learning</a></li>
            <li><a href="#">Business Intelligence</a></li>
            <li><a href="#">Data Analytics</a></li>
            <li><a href="#">DevOps</a></li>
            <li><a href="#">Cyber Sécurité</a></li>
            <li><a href="#">Formation</a></li>
            <li><a href="#">Support</a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-footer-widget">
          <h3>Liens rapides</h3>
          <ul class="quick-links-list">
            <li><a routerLink="/joinus">Carrières</a></li>
            <li><a routerLink="/about">A propos</a></li>
            <li><a routerLink="/contact">Contact</a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-footer-widget">
          <h3>Contacts</h3>
          <ul class="footer-contact-list">
            <li><span>Address:</span> 150 rue Lamarck, 75018 Paris</li>
            <li>
              <span>Email:</span>
              <a href="mailto:contact@cloudiasys.com">contact@cloudiasys.com</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright-area">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6 col-sm-6">
          <p>Copyright 2020 <a href="https://cloudiasys.com/">CloudiaSys</a></p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
          <ul>
            <li><a href="#">Privacy Policy</a></li>
            <li><a href="#">Terms & Conditions</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="circle-map">
    <img src="assets/img/circle-map.png" alt="image" />
  </div>
  <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
</footer>
<!-- End Footer FR Area -->

<!-- Start Footer Area -->
<footer class="footer-area" *ngIf="!foot">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-footer-widget">
          <div class="logo">
            <a href="#"><img src="assets/img/white-logo.png" alt="image" /></a>
            <p>
              We are able to supply needful of help, information, and positive
              experience of maintaining intimacy with customers.
            </p>
          </div>
          <ul class="social">
            <li>
              <a href="#" target="_blank"><i class="flaticon-facebook-letter-logo"></i></a>
            </li>
            <li>
              <a href="#" target="_blank"><i class="flaticon-twitter"></i></a>
            </li>
            <li>
              <a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a>
            </li>
            <li>
              <a href="#" target="_blank"><i class="flaticon-youtube-play-button"></i></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-footer-widget">
          <h3>Services</h3>
          <ul class="footer-services-list">
            <li><a href="#">Data Science</a></li>
            <li><a href="#">Data Analytics</a></li>
            <li><a href="#">Business Intelligence</a></li>
            <li><a href="#">AI Development</a></li>
            <li><a href="#">Data Migration</a></li>
            <li><a href="#">DevOps</a></li>
            <li><a href="#">Cyber Security</a></li>
            <li><a href="#">Training</a></li>
            <li><a href="#">Support</a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-footer-widget">
          <h3>Quick Links</h3>
          <ul class="quick-links-list">
            <li><a href="#">About Us</a></li>
            <li><a href="#">Blog</a></li>
            <li><a href="#">Contact</a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-footer-widget">
          <h3>Contacts</h3>
          <ul class="footer-contact-list">
            <li><span>Address:</span> 150 rue Lamarck, 75018 Paris</li>
            <li>
              <span>Email:</span>
              <a href="mailto:contact@cloudiasys.com">contact@cloudiasys.com</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright-area">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6 col-sm-6">
          <p>Copyright 2020 <a href="https://cloudiasys.com/">CloudiaSys</a></p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
          <ul>
            <li><a href="#">Privacy Policy</a></li>
            <li><a href="#">Terms & Conditions</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="circle-map">
    <img src="assets/img/circle-map.png" alt="image" />
  </div>
  <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
</footer>
<!-- End Footer Area -->

<div class="go-top">
  <i class="fas fa-arrow-up"></i><i class="fas fa-arrow-up"></i>
</div>