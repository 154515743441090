import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-training2',
  templateUrl: './training2.component.html',
  styleUrls: ['./training2.component.css']
})
export class Training2Component implements OnInit {

  valid=false;
  
  valid2=true;
  deno1= {id: 1, chap: "Azure SQL Database - Mettre en œuvre une solution relationnelle",
   def:["M", "P","R","C","S" ]};
  denom = [
    {id: 1, chap: "Implémenter un Data Warehouse avec SQL",
     def:["Introduction à Power BI",
      "Présentation de Power BI Desktop",
      "Présentation de Power BI Service",
      "Explorer un rapport et un tableau de bord avec Power BI",
      "Modèle de licences de Power BI",
      ]},
    {id: 2, chap: " Importer et modéliser les données avec Power BI", 
    def: ["Importer les données dans Power BI",
    "Nettoyer et transformer les données avec Power BI",
      "Définir les relations entre les données",
      "Requêtes DAX dans Power BI",
      "Implémenter les calculs et les mesures dans Power BI",
     ]},
    {id: 3, chap: "Visualiser les données avec Power BI", 
    def: ["Présentation des visualisations dans Power BI",
      "Créer des rapports dans Power BI",
      "Mise en forme des rapports Power BI"
      
     ]},
    {id: 4, chap: "Explorer les données avec Power BI",
     def: ["Présentation du service Power BI",
      "Les règles de conception du tableau de bord Power BI parfait",
      "Créerun tableau de bord Power BI",
      "Explorer les données en langage naturel"
      ]},
    {id: 5, chap: "Publier et Partager avec Power BI", 
    def: ["Publier des rapports Power BI",
      "Imprimer et exporter des tableaux de bord et des rapports Power BI",
      "Présentation de Power BI Mobile",
    	"Utiliser Power BI Mobile",
      "Configurer la sécurité pour les tableaux de bord, les rapports et les applications",
    ]},
    {id: 6, chap: "Préparation à l’examen70-778 : Analyser et visualiser des données avec Microsoft Power BI",
     def: [	"Révision générale du contenu de la formation",
      "Tests pratiquesavec les types de questions figurant dans l'examen"
      
      ]},
    
  ];
  constructor() { }

  ngOnInit() {
  }
  f1(n:number)
  {this.valid=true;
    this.valid2=false;
    for (var i=0;i<this.denom.length;i++)
    if (this.denom[i]["id"]==n)
    {
      this.deno1.chap=this.denom[i].chap;
      this.deno1.def=this.denom[i].def;
     }
     console.log(this.deno1)
  
  }
}
