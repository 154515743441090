
    <!-- Start Page Title Area --
    <div class="main-banner">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container-fluid">
                    <div class="main-banner-content">
                        
                    </div>
                </div>
            </div>
        </div>
    </div>-->   
   <!-- End Page Title Area -->
   <!--Start Page Login Form-->
    <body>
    <div class="container">
        <div class="d-flex justify-content-center h-100">
            <div class="card">
                <div class="card-header">
                    <h3>Sign In</h3>
                    <div class="d-flex justify-content-end social_icon">
                        <span><i class="fab fa-facebook-square"></i></span>
                        <span><i class="fab fa-google-plus-square"></i></span>
                        <span><i class="fab fa-twitter-square"></i></span>
                    </div>
                </div>
               
                <div class="card-body">
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                      
                        <div class="input-group form-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-envelope"></i></span>
                            </div>
                            <!--[(ngModel)]="email" [value]="email"-->
                            <input  type="email"  name ="" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="email"  formControlName="email">
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">email is required</div>
                                <div *ngIf="f.email.errors.pattern" >Please write a valid Email address !</div>
                            </div>
                        </div>
                        <div class="input-group form-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"> </span>
                            </div>
                            <span class="input-group-text">
                            <input id="password" type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="password" >
                            <i  id="pass-status" aria-hidden="true" class="fa fa-eye"  onclick="if (password.type == 'text') password.type = 'password';
                            else password.type = 'text';"></i></span>
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Password is required</div>
                            </div>
                        </div>
                        <div class="row align-items-center remember">
                            <!--[(ngModel)]="password" [value]="password"-->
                            <input formControlName="remember" class ="checkboxxx" type="checkbox" id="autoSizingCheck" >
                            <label class="form-check-label" for="autoSizingCheck">
                                Remember Me
                              </label>
                        </div>
                      
                        <div class="form-group ">
                            <input type="submit" value="login" [disabled]="loading" class="btn btn-lg btn-outline-danger float-right">
                            <div *ngIf="loading" class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                           
        <a routerLink="/register" class="btn btn-outline-warning float-left">Register</a>
                        </div>
                    </form>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-center btn btn-secondary btn-lg active">
                        Don't have an account ?  <br> 
                         <a  style = "color : orange" routerLink="/login">Sign In  </a>
                    </div>
                    <div class="d-flex justify-content-center">
                        <a  class="btn btn-light btn-sm" routerLink="/resetpwd">Forgot your password?</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
   </body>
  <!--End Page Login Form-->