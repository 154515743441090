<div #keyNumbersSection class="container-xxl py-5">
  <div class="container py-5">
    <div class="row">
      <div class="section-title">
        <h2>{{ "key_numbers_section.section_title" | translate }}</h2>
      </div>
    </div>
    <div class="row g-5">
      <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
        <h1 class="mb-4 text-align-center">
          {{ "key_numbers_section.headline" | translate }}
        </h1>

        <h4>
          <i class="fa fa-users mb-3"></i
          >{{ "key_numbers_section.presence_rate" | translate }}
        </h4>
        <h4>
          <i class="fa fa-star mb-3"></i>
          {{ "key_numbers_section.growth_target" | translate }}
        </h4>
        <!-- <div class="d-flex align-items-center">
          <i
            class="fa fa-headphones fa-2x flex-shrink-0 bg-primary p-3 text-white"
          ></i>
          <div class="ps-4">
            <h6>Appelez pour toute question !</h6>
            <h3 class="text-primary m-0">+012 345 6789</h3>
          </div>
        </div> -->
      </div>
      <div class="col-lg-6">
        <div class="row g-4 align-items-center">
          <div class="col-sm-6">
            <div
              class="bg-warning border-raduis-75 p-4 mb-4 wow fadeIn"
              data-wow-delay="0.3s"
            >
              <i class="fa fa-users fa-2x text-white mb-3 ml-40"></i>
              <h2 class="text-white mb-2 text-align-center" id="employes">0</h2>
              <p class="text-white counter-label text-align-center">
                {{ "key_numbers_section.consultants_2023" | translate }}
              </p>
            </div>
            <div
              class="bg-danger border-raduis-75 p-4 wow fadeIn"
              data-wow-delay="0.5s"
            >
              <i class="fa fa-dollar-sign fa-2x text-white mb-3 ml-40"></i>
              <h4
                class="text-white mb-2 text-align-center"
                id="chiffre-affaires"
              >
                0
              </h4>
              <p class="text-white counter-label text-align-center">
                {{ "key_numbers_section.revenue_2023" | translate }}
              </p>
            </div>
          </div>
          <div class="col-sm-6">
            <div
              class="bg-success border-raduis-75 p-4 wow fadeIn"
              data-wow-delay="0.7s"
            >
              <i class="fa fa-star fa-2x text-white mb-3 ml-40"></i>
              <p class="text-white counter-label text-align-center">
                {{ "key_numbers_section.founded_in_2020" | translate }}
                <span class="text-white text-align-center" id="experts">0</span>
                {{ "key_numbers_section.consultants_experts" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
